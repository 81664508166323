import {
  Box,
  Button,
  Container,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { VideoComponent } from "../components/VideoComponent";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// import logoSquare from "../assets/images/logo_square.png";
// import appleDownload from "../assets/images/app_download.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { MutedIcon } from "../assets/icons/muted";
import { SoundIcon } from "../assets/icons/sound";
import Stories from "stories-react";
import "stories-react/dist/index.css";

const key = "RSIsMute";
const WINDOW: any = typeof window === "undefined" ? {} : window;
WINDOW?.localStorage?.setItem(key, "true");

export const APP_STORE_URL =
  "https://apps.apple.com/us/app/facely/id6495064988";

export type SeriesType = {
  id: number;
  created: string;
  modified: string;
  name: string;
  image: string;
  is_published: boolean;
  user: number;
  linker: number;
  is_owner: boolean;
  number_of_episodes: number;
  user_name: string;
};

export type EpisodeType = {
  id: number;
  created: string;
  modified: string;
  image: string;
  series: 1;
  title: string;
  video?: string;
  video_duration?: number;
  formatted_video_duration?: string;
  video_status: "pending" | "uploaded" | "rejected";
  funnel_url?: string;
  funnel_text?: string;
};

export type SeriesDetailsType = SeriesType & { episodes: EpisodeType[] };

export const SeriesViewer = () => {
  const [videoIndex, setVideoIndex] = useState(0);
  const [isAutoPlay, setIsAutoPlay] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<SeriesDetailsType | null>(null);
  const { linkerId, seriesId } = useParams<{
    linkerId: string;
    seriesId: string;
  }>();
  const isReels = false;

  useEffect(() => {
    if (!linkerId || !seriesId) return;
    // setVideoIndex(0);

    // const isDev = process.env.NODE_ENV === "development";
    const isDev = false;
    const apiUrl = isDev
      ? "http://localhost:8000/"
      : "https://server.facely.io/";
    const fetchUrl = `${apiUrl}series/${linkerId}/${seriesId}`;

    const getVideos = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(fetchUrl);
        const data = await response.json();
        setIsLoading(false);
        setData(data);
      } catch (err) {
        setIsLoading(false);
      }
    };
    getVideos();
  }, [linkerId, seriesId]);

  if (isLoading) {
    return (
      <Container
        bg="#BEF264"
        h="100vh"
        position="relative"
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <Spinner color="white" size="xl" thickness="4px" h="3rem" w="3rem" />
      </Container>
    );
  }

  if (!data)
    return (
      <Container
        bg="#BEF264"
        h="100vh"
        position="relative"
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <Text>Something went wrong!</Text>
      </Container>
    );

  if (!data?.episodes) {
    return (
      <Container
        bg="#BEF264"
        h="100vh"
        position="relative"
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <Text fontWeight="bold">No videos found!</Text>
      </Container>
    );
  }

  const stories3 = data.episodes.map((episode) => ({
    url: episode.video || "",
    type: "video",
    duration: episode.video_duration ? episode.video_duration * 1000 : 0,
    isMuted: isMuted,
    header: (
      <Box
        position="absolute"
        top=".5rem"
        left="1rem"
        p="2"
        backgroundColor="rgba(255, 255, 255, 0.6)"
        borderRadius="xl"
        zIndex="1000"
      >
        <Text fontSize="xs">{data.user_name}</Text>
      </Box>
    ),

    seeMore: () => {
      if (episode.funnel_url && episode.funnel_text) {
        return (
          <Button
            position="absolute"
            bottom="40"
            backgroundColor="#BEF264"
            borderRadius="xl"
            fontWeight="bold"
            px="8"
            py="3"
            as="a"
            href={episode.funnel_url}
            target="_blank"
            zIndex="50"
          >
            <Text>{episode.funnel_text}</Text>
          </Button>
        );
      }
    },
  }));

  return (
    <Box
      bg="#BEF264"
      w="full"
      position="relative"
      alignItems="center"
      justifyContent="center"
      display="flex"
      overflow="hidden"
      height="100vh"
      maxW="50rem"
      mx="auto"
    >
      {isReels && (
        <Box
          position="absolute"
          top="3rem"
          right="1rem"
          p="2"
          backgroundColor="rgba(255, 255, 255, 0.6)"
          borderRadius="full"
          onClick={() => {
            setIsMuted(!isMuted);
          }}
          zIndex={1000}
        >
          {isMuted ? <MutedIcon /> : <SoundIcon />}
        </Box>
      )}
      <Helmet>
        <title>{data?.name}</title>
        <meta
          name="description"
          content={`${data?.name} - course by ${data.user_name}`}
        />
      </Helmet>
      {/* <Link
        to={APP_STORE_URL}
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          top: "1rem",
          zIndex: "2",
        }}
      >
        <VStack
          bgColor="rgba(255,255,255,0.6)"
          borderRadius="xl"
          zIndex="2"
          p=".5rem"
          justifyContent="space-between"
          flexDir="column-reverse"
        >
          <Text fontWeight="semibold" fontSize="xs">
            {data.name}
          </Text>
          <HStack gap=".5rem">
            <Image
              src={logoSquare}
              alt="logo"
              borderRadius="xl"
              style={{ width: "2rem" }}
            />
            <Image
              src={appleDownload}
              alt="apple-download"
              style={{ width: "6rem" }}
            />
          </HStack>
        </VStack>
      </Link> */}

      {isReels ? (
        <Carousel
          axis="vertical"
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={100}
          onChange={(index) => setVideoIndex(index)}
          showArrows={false}
          showIndicators={false}
          showStatus={true}
          showThumbs={false}
        >
          {data?.episodes?.map((episode, index) => (
            <VStack key={episode.id} position="relative">
              {episode.funnel_text && episode.funnel_url && (
                <Button
                  position="absolute"
                  bottom="40"
                  backgroundColor="#BEF264"
                  borderRadius="xl"
                  fontWeight="bold"
                  px="8"
                  py="3"
                  as="a"
                  href={episode.funnel_url}
                  target="_blank"
                  zIndex="50"
                >
                  <Text>{episode.funnel_text}</Text>
                </Button>
              )}
              <VideoComponent
                videoSrc={episode.video || ""}
                isFocused={index === videoIndex}
                isMuted={isMuted}
                setIsMuted={setIsMuted}
                isAutoPlay={isAutoPlay}
                setIsAutoPlay={setIsAutoPlay}
              />
            </VStack>
          ))}
        </Carousel>
      ) : (
        <Stories
          stories={stories3}
          loop
          height={window.innerHeight}
          currentIndex={videoIndex}
          onStoryChange={(index: number) => {
            setVideoIndex(index);
            WINDOW?.localStorage?.setItem(key, String(true));
          }}
        />
      )}
    </Box>
  );
};
